
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import * as bootstrap from 'bootstrap';

	import type { TagModel } from '@common/models/kiosk';

	@Component
	export default class extends Vue {
		@Prop() public tags!: string[];

		private modal!: bootstrap.Modal;
		protected value: string = "";
		protected tag: TagModel | null = null;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
		}

		protected get others(): string[] {
			return this.tags.filter(t => t != this.tag?.Name);
		}

		public show(t: TagModel): void {
			this.tag = t;
			this.value = t.Name;
			this.modal.show();
		}

		protected async ok(): Promise<void> {
			this.modal.hide();
			await post('/api/tag/edit', { Id: this.tag!.Id, Name: this.value });
			// eslint-disable-next-line require-atomic-updates
			this.tag!.Name = this.value;
		}
	}
