
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { now, pad } from "@common/date";
	import { MerchantKioskType } from "@common/models/kiosk";
	import { KioskTransactionType } from "@/models/Report";
	import { sum } from "@/views/utility";
	import Base from "../Base";
	import Revenue from "./components/Revenue.vue";
	import Summary from "./components/Summary.vue";
	import Transactions from "./components/Transactions.vue";

	import Statistics from "./components/Statistics.vue";
	import type { KioskModel } from "@common/models/kiosk";
	import type { StatisticsItem } from "./components/Statistics.vue";
	import type { KioskDailyReport, ModifyRequest, ReportModel, KioskTransaction, RevenueModel } from "@/models/Report";

	@Component({ components: { Revenue, Summary, Statistics, Transactions } })
	export default class extends Base {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;
		@Prop(String) public id!: string;
		@Prop(String) public date!: string;

		protected data: KioskDailyReport | null = null;
		protected markMode: boolean = false;

		mounted(): void {
			this.load();
		}

		protected get kiosk(): KioskModel {
			return this.data!.MerchantKiosk.Kiosk;
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.MerchantKiosk.Name;
		}

		protected get canSetTestData(): boolean {
			if(!this.data) return false;
			const mk = this.data.MerchantKiosk;
			return mk.IsActive && (mk.Type == MerchantKioskType.admin || mk.Type == MerchantKioskType.moderator);
		}

		protected get statistics(): StatisticsItem[] {
			let result: StatisticsItem[] = [];
			if(!this.data) return result;
			for(let i = 0; i < 24; i += 2) {
				result.push({
					title: `${pad(i)}:00 - ${pad(i + 2)}:00`,
					play: 0,
					gift: 0,
				});
			}
			for(let t of this.data.Transactions) {
				if(this.markMode ? t.isTestData : t.IsTestData) continue;
				let h = Math.floor(t.Time.getHours() / 2);
				if(t.Type == KioskTransactionType.Giftout) result[h].gift++;
				else result[h].play += t.Amount;
			}
			return result;
		}

		private async load(): Promise<void> {
			this.data = null;
			try {
				this.data = await post<KioskDailyReport>("/api/Report/Daily", {
					Id: this.id,
					Start: this.date,
				});
				this.update = now();
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected toMarkMode(): void {
			if(!this.data) return;
			this.data.Transactions.forEach(t => Vue.set(t, "isTestData", t.IsTestData));
			this.markMode = true;
		}

		protected get markSummary(): ReportModel[] {
			if(!this.data) return [];
			const transactions = (this.data.Transactions ?? []).filter(t => !t.isTestData);
			return this.data.Reports.map(r => {
				const trans = transactions.filter(t => r.StartTime <= t.Time && t.Time < r.EndTime);
				const giftout = sum(trans.filter(t => t.Type == KioskTransactionType.Giftout), t => t.Amount);
				const total = sum(trans.filter(t => t.Type != KioskTransactionType.Giftout), t => t.Amount);
				return {
					StartTime: r.StartTime,
					EndTime: r.EndTime,
					Price: r.Price,
					Giftout: giftout,
					Total: total,
					Coin: r.Coin,
					Remote: r.Remote,
					ProfitRate: 0,
					Profit: r.Price === undefined ? 0 : total * 10 - r.Price * giftout,
				};
			});
		}

		protected get markRevenue(): RevenueModel[] {
			const result: RevenueModel[] = JSON.parse(JSON.stringify(this.data?.Revenues ?? []));
			for(const r of result) {
				if(r.Name == "現金投幣") {
					r.Amount = (this.data?.Transactions ?? [])
						.filter(t => !t.isTestData && t.Type == KioskTransactionType.Coin)
						.reduce((v, t) => v + t.Amount, 0);
				}
			}
			return result;
		}

		protected async mark(): Promise<void> {
			if(!this.data) return;
			try {
				const request: ModifyRequest = {
					HardwareId: this.data.MerchantKiosk.Kiosk.HardwareId,
					Events: [],
				};
				for(const t of this.data.Transactions) {
					if(t.isTestData != t.IsTestData) {
						request.Events.push({
							Type: t.Type == KioskTransactionType.Coin ? "CoinEvent" : "GiftoutEvent",
							Time: t.Time.toISOString(),
							Enable: !t.isTestData!,
						});
					}
				}
				if(!await post<boolean>("/api/Report/ModifyTestData", { ...request })) throw new Error("未知錯誤");
				this.data.Giftout = sum(this.markSummary, r => r.Giftout);
				this.data.Total = sum(this.markSummary, r => r.Total);
				this.data.Revenues = this.markRevenue;
				for(const t of this.data.Transactions) t.IsTestData = t.isTestData!;
			} catch(e) {
				if(e instanceof Error) this.alert(e.message, "儲存失敗", "error");
			} finally {
				this.markMode = false;
			}
		}
	}
